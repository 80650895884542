import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Helmet} from "react-helmet";
const TechnicalSupport = ()=>{
    return (
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Business </title>
            <meta data-meta-title="IANS LIVE" name="keywords"  />
            <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header />
         <main id="main">
            <section className="inner-page">
            <div className="container">
                <div className="news-cat">
                    <h2>Technical Support</h2>
                </div>
                <div className="col-md-12">
                    <h3>Support</h3>
                    <p>For technical queries, please send an email to&nbsp;<a href="mailto:itsupport@ians.in"><strong>itsupport@ians.in</strong></a></p>
                    <div className="contact-details">
                        <p align="justify">
                            <strong>IANS India Pvt Ltd</strong><br />
                            A-6, A Block <br />
                            Sector-16,<br />
                            Noida-201301 U.P. <br />
                            <i className="fa fa-phone" aria-hidden="true"></i> +91-120-4822400 <br />
                            <i className="fa fa-fax" aria-hidden="true"></i> +91-120-4822403, +91-120-4822404<br />
                        </p>
                    </div>
                    <br />
                </div>
            </div>
            </section>
        </main>
        <Footer />
        </>
    )
}
export default TechnicalSupport