import React from 'react';
import {NavLink} from 'react-router-dom';

const Sidebar=(props)=>{
    return(
            <div className="col-lg-4">
               <div className="sidebar">
                <h3 className="sidebar-title">Recent Posts</h3>
                <div className="sidebar-item recent-posts">
                        {props && props.data && props.data.length > 0 && props.data.map((recen, r)=>{
                            return(
                                <div className="post-item clearfix" key={r}>
                                    <img src={recen.image} alt=""/>
                                    <h4><NavLink to={recen.slug}>{recen.title}</NavLink></h4>
                                    <time>{recen.dateTime}</time>
                                </div>
                            )})}
                </div>
               </div>
            </div>        
        )
}
export default Sidebar