import {NavLink} from 'react-router-dom';

const Market =(props)=>{
    return(
		<section id="featured" className={props && props.cls ? props.cls : ''}>
		  <div className="container">
			<h2 className="block-title">
            <span><NavLink to={props && props.link ? props.link :'#'}>{props && props.name ? props.name :''} <i className="bi-arrow-right"></i></NavLink></span>
			</h2>
			<div className="row">
			  <div className="col-lg-3 mt-3 mt-lg-0">
                {props && props.data && props.data.length > 0 && props.data.slice(1,3).map((dat, d)=>{
                    return(
                        <div className="business-hcet" key={d}>
                            <div className="post-img">
                                <div className="item">
                                    <NavLink to={dat.slug}>
                                        <img src={dat.image}/>
                                    </NavLink>
                                </div>                   
                            </div>
                            <div className="post-content">
                                <div className="post-meta">
                                    <span>{dat.tags && dat.tags.length > 0 && dat.tags[0].name ? dat.tags[0].name:''} | {dat.dateTime}</span>
                                </div>
                                <h3 className="post-title">
                                    <NavLink to={dat.slug}>{dat.title}</NavLink>
                                </h3>
                                <NavLink to={dat.slug} className="w-more-btn">Read More</NavLink>
                            </div>	
                        </div>
                )})}
			  </div>
			  <div className="col-lg-6 mt-6 mt-lg-0">
            {props && props.data && props.data.length > 0 &&
				<div className="business">
					<div className="post-img">
						<div className="item">
							<a  href=""><img src={props.data[0].image}/></a>
						</div>                   
					</div>
					<div className="post-content">
						<div className="post-meta">
							<span>{props.data[0].tags && props.data[0].tags.length > 0 && props.data[0].tags[0].name ? props.data[0].tags[0].name:''} | {props.data[0].dateTime}</span>
						</div>
						<h3 className="post-title">
							<NavLink to={props.data[0].slug}>{props.data[0].title}</NavLink>
						</h3>
						<p>{props.data[0].content}</p>
						<NavLink to={props.data[0].slug} className="w-more-btn">Read More</NavLink>
					</div>	
				</div>
            }
			  </div>
			  <div className="col-lg-3 mt-3 mt-lg-0">
                {props && props.data && props.data.length > 0 && props.data.slice(3,5).map((dat2, d2)=>{
                    return(
                        <div className="business-hcet" key={d2}>
                            <div className="post-img">
                                <div className="item">
                                    <NavLink  to={dat2.slug}><img src={dat2.image}/></NavLink>
                                </div>                   
                            </div>
                            <div className="post-content">
                                <div className="post-meta">
                                    <span>{dat2.tags && dat2.tags.length > 0 && dat2.tags[0].name ? dat2.tags[0].name:''} | {dat2.dateTime}</span>
                                </div>
                                <h3 className="post-title">
                                    <NavLink to={dat2.slug}>{dat2.title}</NavLink>
                                </h3>
                                <NavLink to={dat2.slug} className="w-more-btn">Read More</NavLink>
                            </div>	
                        </div>
                        )})}
			  </div>
			</div>
		  </div>
		</section>       
    )
}
export default Market;