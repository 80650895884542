import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import ApiUrls from './ApiUrls'

const Category = ()=>{
const [cateData, setCateData] = useState({});
const {categoryId, subCat, offset} = useParams();
const [currentPage, setCurrentPage] = useState(1);
const [lt, setLt] = useState(1);

const formatDate = (timestamp)=>{
  const date = parseISO(timestamp);
  return format(date, 'MMMM dd, yyyy h:mm a');
  }
const fetchData = async (page) => {
    let web = '';
    if(categoryId && categoryId == 'science-tech'){
        web = `&website=2`;
    }
const getApii = `${ApiUrls.URL}news/portals/?language=english${web}&tags=${categoryId}&page=${page}`;
   axios.get(getApii)
     .then(res => {

        const getCatData =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.map((item) => {
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            tags: item.tags,
            slug:'/detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at) : '',
            image_caption: item.image_caption,
        }});
        setCateData(getCatData);
        setLt(res.data.numpages);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })
}
    useEffect(() => {
      fetchData(currentPage);

    //  .catch(err => { console.log('Google api calendar error', err) })
    }, [currentPage, categoryId])
    return(
        <>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>BUSINESS IANS -{categoryId ? categoryId.toUpperCase() : ''} NEWS</title>
            <meta data-meta-title="BUSINESS IANS" name="keywords" content="BUSINESS IANS, BUSINESS IANS News, latest updates, live updates, business updates, Indian news, online news portal, Bollywood updates, breaking headlines, current events, global news, sports coverage, latest India updates, news highlights, news platform, top stories, India updates, entertainment buzz, national affairs, political happenings" />
            <meta data-meta-title="BUSINESS IANS" name="description" content="Stay informed with BUSINESS IANS News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
          </Helmet>
          <Header />
          <main>	
            <section id="featured" className="featured black-bg">
              <div className="container">			
              <div className="row news-category-list">
              <h2 className="block-title">
                <span><a href="#">{categoryId ? categoryId.toUpperCase() : ''} </a></span>
              </h2>
              {cateData && cateData.length > 0 && cateData.map((cate, c)=>{
                  return(
                      <div className="col-lg-4 mt-4 mt-lg-0" key={c}>
                        <div className="business-cet-sec">
                            <div className="post-img">
                                <div className="item">
                                    <NavLink className="" to={cate.slug}><img src={cate.image}/></NavLink>
                                </div>                   
                            </div>
                            <div className="post-content">
                                <div className="post-meta">
                                    <span>{cate && cate.tags && cate.tags.length > 0 && cate.tags[0].name ? cate.tags[0].name:''} | {cate.dateTime}</span>
                                </div>
                                <h3 className="post-title">
                                    <NavLink to={cate.slug}>{cate.title}</NavLink>
                                </h3>
                                <p>{cate.content}</p>
                            </div>	
                        </div>
                      </div>
                  )})}
                <div className="blog-pagination">
                  <ul className="justify-content-center">
                            {currentPage > 1 && <li onClick={()=>{
                                  setCurrentPage(currentPage-1)
                              }}><a href="#">{currentPage-1}</a></li>}
                              <li className='active' onClick={()=>{
                                  setCurrentPage(currentPage)
                              }}><a href="#">{currentPage}</a></li>
                            { <li onClick={()=>{
                                  setCurrentPage(currentPage+1)
                              }}><a href="#">{currentPage+1}</a></li>}
                              <li onClick={()=>{
                                  setCurrentPage(currentPage+2)
                              }}><a href="#">{currentPage+2}</a></li>
                  </ul>
                </div>
              </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
    )
}
export default Category