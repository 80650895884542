import {NavLink} from 'react-router-dom';

const Business =(props)=>{
    return(
        <>
		<section id="featured" className="featured bg-business-sec">
		  <div className="container">		
			<h2 className="block-title">
				<span><NavLink to={props && props.link ? props.link :'#'}>{props && props.name ? props.name :''} <i className="bi-arrow-right"></i></NavLink></span>
			</h2>
			<div className="row">
                {props && props.data && props.data.length > 0 && props.data.map((bus, b)=>{
                    return(
                        <div className="col-lg-4 mt-4 mt-lg-0" key={b}>
                        <div className="business-cet-sec">
                            <div className="post-img">
                                <div className="item">
                                    <NavLink className="" to={bus.slug}><img src={bus.image}/></NavLink>
                                </div>                   
                            </div>
                            <div className="post-content">
                                <div className="post-meta">
                                    <span>{bus.tags && bus.tags.length > 0 && bus.tags[0].name ? bus.tags[0].name:''} | {bus.dateTime}</span>
                                </div>
                                <h3 className="post-title">
                                    <NavLink to={bus.slug}>{bus.title}</NavLink>
                                </h3>
                                <p>{bus.content}</p>
                            </div>	
                        </div>
                      </div>                        
                    )
                })}
			</div>
		  </div>
		</section>       
        </>
    )
}

export default Business;