import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ApiUrls from './ApiUrls'

const Search = ()=>{
const [cateData, setCateData] = useState({});
const {search} = useParams();
const [searchD , setSerchData] = useState('');
const [currentPage, setCurrentPage] = useState(1);
const [lt, setLt] = useState(0);


const formatDate = (timestamp)=>{
  const date = parseISO(timestamp);
  return format(date, 'MMMM dd, yyyy h:mm a');
  }
//alert(search);
const fetchData = async (page) => {
const getApii = `${ApiUrls.URL}news/portals/?language=english&website=2&query=${search}&page=${page}`;
   axios.get(getApii)
     .then(res => {
const getCatData =
res.data.results &&
res.data.results.length > 0 &&
res.data.results.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.thumbnail,
    title: item.title,
    tags:item.tags,
    dateTime: item.created_at ? formatDate(item.created_at) : '',
    slug:'/detail/'+item.slug,
    image_caption: item.image_caption,
};
});
        setCateData(getCatData);
        setLt(res.data.numpages);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })
}
useEffect(() => {
    if(localStorage.getItem('searchData')){
        setSerchData(localStorage.getItem('searchData'));
    }
  fetchData(currentPage);
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, search])

    return(
        <>
          <Header />
          <main>	
            <section id="featured" className="featured black-bg">
              <div className="container">			
                <div className="row news-category-list">
                  <h2 className="block-title">
                    <span><a href="#">{search ? search.toUpperCase() : ''} </a></span>
                  </h2>
                {cateData && cateData.length > 0 && cateData.map((cate, c)=>{
                  return(
                        <div className="col-lg-4 mt-4 mt-lg-0" key={c}>
                          <div className="business-cet-sec">
                              <div className="post-img">
                                  <div className="item">
                                      <NavLink className="" to={cate.slug}><img src={cate.image}/></NavLink>
                                  </div>                   
                              </div>
                              <div className="post-content">
                                  <div className="post-meta">
                                      <span>{cate && cate.tags && cate.tags.length > 0 && cate.tags[0].name ? cate.tags[0].name:''} | {cate.dateTime}</span>
                                  </div>
                                  <h3 className="post-title">
                                      <NavLink to={cate.slug}>{cate.title}</NavLink>
                                  </h3>
                                  <p>{cate.content}</p>
                              </div>	
                          </div>
                        </div>
                )})}
                { lt > 0 &&
                  <div className="blog-pagination">
                    <ul className="justify-content-center">
                      {currentPage > 1 && 
                        <li onClick={()=>{ setCurrentPage(currentPage-1) }}><a href="#">{currentPage-1}</a>
                        </li>
                      }
                        <li className='active' onClick={()=>{ setCurrentPage(currentPage) }}><a href="#">{currentPage}</a>
                        </li>
                      { lt > currentPage && 
                        <li onClick={()=>{ setCurrentPage(currentPage+1)}}><a href="#">{currentPage+1}</a>
                        </li>
                      }
                      { lt > currentPage+1 &&
                        <li onClick={()=>{ setCurrentPage(currentPage+2)}}><a href="#">{currentPage+2}</a>
                        </li>
                      }
                    </ul>
                  </div>
                }
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
    )
}
export default Search