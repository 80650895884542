import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import Top from './Top'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import Business from './Business';
import Market from './Market';
import ApiUrls from './ApiUrls'

const Home =()=>{
    const [topData, setTopData] = useState({})
    const [bussData, setBussData] = useState({})
    const [maketList, setMarketListData] = useState({})
    const [economyList, setEconomyListData] = useState({})    
    const formatDate = (timestamp)=>{
      const date = parseISO(timestamp)
      return format(date, 'MMMM dd, yyyy h:mm a')
      }
    const topSData =()=>{
      console.log('tttt', ApiUrls)
        axios
        .get(
        `${ApiUrls.URL}elastic/news/list/?language=english&topNews_category=4`
        )
        .then((res) => {
        const topStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/detail/'+item.slug,
                tags: item.tags,
				        dateTime: item.created_at ? formatDate(item.created_at) : '',
                image_caption: item.image_caption,
            };
            });
        setTopData(topStories);
        });
    }
    const MarketData =(cate)=>{
        axios
        .get(
        `${ApiUrls.URL}news/portals/?language=english&tags=${cate}&page=1`
        )
        .then((res) => {
        const marketStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 6).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                tags: item.tags,
                title: item.title,
                slug:'/detail/'+item.slug,
                dateTime: item.created_at ? formatDate(item.created_at) : '',
                image_caption: item.image_caption,
            }})
            if(cate && cate == 'market'){
              setMarketListData(marketStories);
            }else if(cate && cate == 'economy'){
              setEconomyListData(marketStories);
            }else if(cate && cate == 'business'){
              setBussData(marketStories);
            }
        });
    }
    useEffect(() => {
        topSData()
        MarketData('business')
        MarketData('market')
        MarketData('economy')
      }, []);
       
    return(
        <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="icon" href="icon-new.ico" type="image/x-icon" />
          <link rel="shortcut icon" href="/theme/bollywood/images/favicon.ico" type="image/x-icon" />
          <title>BUSINESS LIVE</title>
          <meta data-meta-title="IANS LIVE" name="keywords" content="IANS LIVE, IANS LIVE News, latest updates, live updates, business updates, Indian news, online news portal, Bollywood updates, breaking headlines, current events, global news, sports coverage, latest India updates, news highlights, news platform, top stories, India updates, entertainment buzz, national affairs, political happenings" />
          <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
        <Header />
        <main id="main">
          <Top data ={topData}/>
          <Market data={maketList} name={"Market"} cls={"featured bg-business-sec"} link={'/category/market'}/>
          <Market data={economyList} name={"Economy"} cls={"featured black-bg"} link={'/category/economy'}/>
          <Business data={bussData} name="Business" link={'/category/business'}/>
	      </main>  
        <Footer />      
        </>
    )
}

export default Home