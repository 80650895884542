import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Helmet} from "react-helmet";
const Services = ()=>{
    return (
        <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <title>IANS </title>
          <meta data-meta-title="IANS LIVE" name="keywords"  />
          <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header />
         <main id="main">
          <section className="inner-page">
            <div className="container">
              <div className="news-cat">
                  <h2>SERVICES US</h2>
              </div>
              <div className="col-md-12">
                  <h3>IANS News Service</h3>
                  <p><strong>English Service:</strong>The IANS English wire service forms the heart of IANS operations. It presents objective, user-friendly news in a global perspective. This makes it the content provider of choice for Indians and people keen on India, across the world.</p>
                  <p>IANS breaks through the often cliched, stereotyped and statist reporting of traditional news agencies. Its talented team of journalists and contributors - some of the best in India - produces a steady output of diverse, well-researched news and backgrounders, features, interviews, analyses and commentaries that take into account the aspirations of the emerging New India.</p>
                  <p>IANS coverage spans politics and public policy, strategic and corporate affairs, business and economy, arts and literature, environment and social issues, religion and lifestyle, entertainment and sports.</p>
                  <p>Given its collaborations with several international news organisations, the IANS service is often used by media as a one-stop source of all news - both domestic and international. Industry, media circles and government departments depend on it. Officials use it for policy publicity and public diplomacy.</p>
                  <p>IANS serves over 500 subscribers across the entire media spectrum - print, web, electronic, mobile -- in India and around the world. It performs a whole range of other information services as well, and is anchored by experienced media professionals.</p>
                  <p>IANS has a network of over 150 correspondents in India, reporting from bureaus in New Delhi, Mumbai, Kolkata, Lucknow and Chennai, as well as every other state capital. It has one of the largest networks of foreign correspondents for any Indian media, reporting from key datelines like Washington, London, Johannesburg, Dubai, Islamabad, Kathmandu, Dhaka, Colombo, Singapore and Sydney. The service puts out over 350 stories every day.</p>
                  <p><strong>Website:</strong>&nbsp;<a href="http://www.ians.in/" target="_blank">www.ians.in</a></p>
                  <p><strong>Hindi Service:</strong>In 1995, IANS started its Hindi service. Today, IANS Hindi provides content in India's most widely spoken and understood language. Its subscriber base of over 100 encompasses all leading Hindi newspapers, websites and other news outlets. Generating a substantial amount of high quality original content, IANS Hindi has an independent network of correspondents spread across the Hindi-speaking states of India. It puts out over 150 stories a day.</p>
                  <p><strong>Website:</strong>&nbsp;<a href="http://www.ianshindi.in/" target="_blank">www.ianshindi.in</a></p>
                  
                  <h3>IANS Multimedia</h3>
                  <p>To cater to an increasingly digital age, IANS launched IANS Photos in 2001, initially focusing on the popular entertainment industry. IANS Photos has since been expanded into a full-fledged photo service, putting out over 400 pictures every day and with its own international collaborations.</p>
                  <p>IANS Videos was created to cater to the increasing multimedia - both audio &amp; video -- needs of websites and other digital and mobile platforms. Today, IANS Video generates over 500 videos a month covering the entire spectrum from entertainment and sports to politics and general interest stories.</p>
                  <p><strong>Website/s:</strong>&nbsp;<a href="http://www.iansphoto.in/" target="_blank">www.iansphoto.in</a>,&nbsp;<a href="http://www.iansvideo.com/" target="_blank">www.iansvideo.com</a>,&nbsp;<a href="http://multimedia.ians.in/" target="_blank">multimedia.ians.in</a></p>
                  
                  <h3>IANS Software Services</h3>
                  <p>IANS Software Services provides all the answers for developing an Internet strategy. It blends design, technology and marketing expertise to create a Web, Mobile and Social Media presence that delivers outstanding results. Talented website designers collaborate with our technology group to create personalised, graphically superior, functionally sound mobile applications, web-based applications, websites.</p>
                  <p><strong>Website:</strong>&nbsp;<a href="http://ianssoftware.com/" target="_blank">ianssoftware.com</a></p>
                  <br />
              </div>
            </div>
          </section>
         </main>
        <Footer />
        </>
    )
}
export default Services