import React,{useEffect, useState} from 'react'
import { NavLink, useParams, useHistory} from 'react-router-dom'

const Header =()=>{
    const locale = 'en';
	  const {categoryId} = useParams();
    const [mobileCss, setMobileCss] = useState(true)
    const navLinks = [
      { label: 'Home', to: '/' },
      { label: 'Business', to: '/category/business' },
      { label: 'Economy', to: '/category/economy' },
      { label: 'Market', to: '/category/market' },
      { label: 'Science/Tech', to: '/category/science-tech' }
    ]
    const navItems = navLinks.map((link, index) => (
      <li key={index}>
        <NavLink className={`nav-link scrollto`} to={link.to}>
          {link.label}
        </NavLink>
      </li>
    ));
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
    const year = new Date().getFullYear();
    const date = `${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${year} \n\n`;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    const [search, setSearch] = useState();
    const inputData=(event)=>{
        setSearch(event.target.value);
      }
    const history = useHistory();
    const onSubmit = (e) => {
      e.preventDefault()
        if(search){
          let path = `/search/` +search;
          history.push(path);
        }
    }
	const toggleMobileCss = () => {
		setMobileCss(!mobileCss);
	  }; 
    useEffect(() => {

	  setMobileCss(true)
    }, [categoryId]);
    return (
        <>
	   <section id="topbar" className="d-flex align-items-center nav-bord">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <NavLink to="/">Last updated on  {date} {time}</NavLink>
        </div>
        <div className="social-links d-none d-md-flex align-items-center">
          <a href="https://twitter.com/ians_india" target="_blank" className="twitter"><i className="bi bi-twitter"></i></a>
          <a href="https://www.facebook.com/iansnews.in/" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="https://www.instagram.com/iansnews.in/" target="_blank" className="instagram"><i className="bi bi-instagram"></i></a>
        </div>
      </div>
	  </section>
	  <div className="logo">
      <NavLink to="/">
        <img src="/assets/img/IANS-BUSINESS-logo-8.png" alt=""/>
      </NavLink>	
	  </div>
	  <header id="header" className="d-flex align-items-center sticky-top">
      <div className="container d-flex justify-content-between align-items-center">
        <nav id="navbar" className={mobileCss ? 'navbar' : 'navbar navbar-mobile'} >
          <ul> 
            {navItems}
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileCss}></i>
        </nav> 
        <form onSubmit={onSubmit}>
          <div className="search-form input-group">
            <input type="search" onChange={inputData} name="search" value={search} className="form-control" placeholder="Search" aria-label="Search" aria-describedby="search-addon"/>
            <span className="input-group-text border-0" id="search-addon" onClick={onSubmit}>
              <i className="bi bi-search"></i>
            </span>
          </div>
        </form>
      </div>
	  </header>
        </>
    )
}
export default Header;