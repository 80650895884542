import { NavLink } from 'react-router-dom'

const Footer = () =>{
	const year = new Date().getFullYear();
    return (
		<footer id="footer">
			<div className="footer-top">
				<div className="container">	  
					<div className="row">		
						<div className="col-lg-12 col-md-6 footer-info">
							<h2 className="footer-title">
								<span><NavLink to="/">FOLLOW </NavLink></span>
							</h2>
							<div className="social-links mt-3">
							<a href="https://twitter.com/ians_india" target="_blank" className="twitter"><img src="/assets/img/twitter.webp" width="24" height="24"/> FOLLOW ON TWITTER</a>
							<a href="https://www.facebook.com/iansnews.in/" target="_blank" className="facebook"><img src="/assets/img/facebook.webp" width="24" height="24"/> FOLLOW ON FACEBOOK</a>
							<a href="https://www.instagram.com/iansnews.in/" target="_blank" className="instagram"><img src="/assets/img/instagram.webp" width="24" height="24"/> FOLLOW ON INSTAGRAM</a>
							</div>
						</div>			
						<div className="col-lg-12 col-md-6 footer-info">
							<h2 className="footer-title">
								<span><NavLink to="/">Explore the IANS </NavLink></span>
							</h2>
							<ul>
								<li><NavLink to="/">Home</NavLink></li>
								<li><a href="https://ians.in/" target="_blank">IANS English News</a></li>
								<li><a href="https://ianshindi.in/" target="_blank">IANS Hindi News</a></li>
								<li><a href="https://www.iansphoto.in/" target="_blank">IANS Photo</a></li>
								<li><a href="https://www.iansvideo.com/" target="_blank">IANS Video</a></li>
								<li><a href="https://www.ianstv.in" target="_blank">IANS TV</a></li>
								<li><a href="https://ianslife.in/" target="_blank">IANS Life</a></li>
								<li><a href="https://ianslive.in/" target="_blank">IANS Live</a></li>
								<li><a href="http://ianswellness.in/" target="_blank">IANS Wellness</a></li>
								<li><a href="https://bollywoodcountry.in/" target="_blank">Bollywood Country</a></li>
								<li><a href="https://www.cricketfanatic.in/" target="_blank">Cricket Fanatic</a></li>
								<li><a href="#" target="_blank">Khabar Filmy</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			
			<div className="container">
			<ul class="list-unstyled">
				<li><a href="/about-us">About US</a></li>
				<li><a href="/privacy-policy">Privacy Policy </a></li>
				<li><a href="/terms-of-use">Terms Of Use</a></li>
				<li><a href="/contact-us">Contact Us</a></li>
			</ul>
				<div className="copyright">
					<p>Copyright © {year} Indo-Asian News Service (IANS) India Private Limited. All Rights Reserved. Web Design by: <a href="http://ianssoftware.com/" target="_parent" title="IANS Software Services">IANS Software Services</a></p>
				</div>
			</div>
		</footer>      
    )
}
export default Footer