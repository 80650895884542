import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import ApiUrls from './ApiUrls'

const Detail = () =>{
const {newsId} = useParams();
const [collectionData, setCollectionData] = useState([])
const [latData, setLatestData] = useState({})
const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
 }
const LatestData =()=>{
   axios
   .get(
   `${ApiUrls.URL}elastic/news/list/?language=english&topNews_category=4`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.map((item) => {
       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/detail/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at) : '',
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const getData = async() =>{
   const apiD= `${ApiUrls.URL}news/portals/${newsId}`
   axios.get(apiD)
.then(res =>
  {

   let main_content_string = res.data.results[0].content;
   let iansIndex = main_content_string.indexOf("--IANS") ? main_content_string.indexOf("--IANS") : main_content_string;
   let new_main_content_string = main_content_string.substring(0, iansIndex);
   setCollectionData({
   byline: res.data.results[0].byline,
   content: '<p>'+res.data.results[0].short_desc +'</p>' + new_main_content_string,
   id: res.data.results[0].id,
   dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at) : '',
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   img_slug: res.data.results[0].images_thumbnails[0].slug,
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
   console.log("Your new array of modified objects here")
})
.catch(err => { console.log('Google api calendar error', err) })
}
 useEffect(() => {
   LatestData();
   getData();
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>BUSINESS LIVE-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS LIVE" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
         </Helmet>
         <Header />
         <main >
            <section id="blog" className="blog">
               <div className="container" data-aos="fade-up">
                  <div className="row news-category-list">
                     <div className="col-lg-8 entries">
                        <article className="entry">
                           <h2 className="entry-title">{collectionData.title}</h2>
                           <div className="entry-img">
                              <a target = "_blank" href={'https://iansphoto.in/detail/'+collectionData.img_slug} title={collectionData.title}>
                                 <img className="img-fluid" src={collectionData.image} alt={collectionData.title} />
                              </a>	
                           </div>
                           <div className="entry-meta">
                              <ul>
                                 <li className="d-flex align-items-center"><i className="bi bi-person"></i> <NavLink to="#">{collectionData.byline ? collectionData.byline :'IANS'}</NavLink></li>
                                 <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <NavLink to="#">{collectionData.dateTime}</NavLink></li>
                              </ul>
                           </div>
                           <div className ="entry-content" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                        </article>
                     </div>
                     <Sidebar data={latData}/>
                  </div>
               </div>
            </section>
         </main>
         <Footer/>
        </>
    )
}

export default Detail