import Footer from './Footer'
import Header from './Header'
import {Helmet} from "react-helmet";
const About = ()=>{
    return (
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Business </title>
            <meta data-meta-title="IANS LIVE" name="keywords"  />
            <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header />
         <main id="main">
            <section  id="blog" className="blog">
                <div className="container" data-aos="fade-up">
                    <div className="row news-category-list">
                        <h2 class="block-title"><span><a href="">ABOUT US</a></span></h2>
                        <div className="col-md-12">
                            <h3>The IANS Edge</h3>
                            <p>With India's growing importance in international strategic and economic thinking, news about the country is becoming more critical to the world. IANS provides news and analyses from India and South Asia from the region's perspective.</p>
                            <p><strong>IANS</strong> was established in 1986, initially to serve as an information bridge between India and its thriving Diaspora in North America.</p>
                            <p>Three decades later, it is a full-fledged wire agency, putting out news 24x7 from India and South Asia, and covering events of interest to this region from around the world.</p>
                            <p>IANS is, however, more than just India's largest independent news service. It is now the preferred source for all manner of content and information.</p>
                            <p>A media powerhouse, IANS is divided into six business verticals: IANS News Service (English &amp; Hindi), IANS Multimedia, IANS Publishing, IANS Media Consultancy, IANS Software Services, IANS Mobile Services. Each vertical is headed by a distinguished professional with strong domain expertise.</p>
                            <p>The IANS client list includes practically every major print publication in India, top television news channels, websites, mobile operators, ethnic publications abroad, central and state government ministries and departments, overseas Indian missions, corporates and public policy institutions.</p>
                            <p>IANS is today one of India's most reliable, independent and professional news wholesalers, providing high quality content across all media platforms.</p>
                            <div align="center">-----------</div>
                            <div className="contact-details">
                                <p>
                                <strong>For More Information:</strong><br />
                                <br />
                                <strong>IANS India Pvt Ltd</strong><br />
                                A-6, A Block <br />
                                Sector-16,<br />
                                Noida-201301 U.P. <br />
                                <i className="fa fa-phone" aria-hidden="true"></i> +91-120-4822400 <br />
                                <i className="fa fa-fax" aria-hidden="true"></i> +91-120-4822403, +91-120-4822404<br />
                                </p>
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
        </main>
        <Footer />
        </>
    )
}
export default About