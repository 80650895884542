import {NavLink} from 'react-router-dom';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Top=(props)=>{
    return(
        <>
		<section id="portfolio-details" className="portfolio-details">
		  <div className="container">
			<div className="row gy-4">
			  <div className="col-lg-12">
				<Swiper
					spaceBetween={30}
					centeredSlides={true}
					autoplay={{
					delay: 2500,
					disableOnInteraction: false,
					}}
					pagination={{
					clickable: true,
					}}
					navigation={false}
					modules={[Autoplay, Pagination, Navigation]}
					className="align-items-center"
				>
		        	{props && props.data && props.data.length > 0 && props.data.slice(0, 4).map((top, tw)=>{
                    return(   
						<SwiperSlide key={tw}>
									<div className="swiper-slide" >
										<div className="bus-feed-item-primary">
											<div className="biz-feed-item-primary-element">
												<img alt="" src={top.image} className="lazy-img-bg"/>
												<div className="biz-feed-item-element-meta">
													<div className="biz-primary-title"><NavLink to={top.slug}>{top.title}</NavLink></div>
													<div className="post-meta">
													<span>{top.tags && top.tags.length > 0 ? top.tags[0].name :''} | {top.dateTime}</span>
													</div>
													<p>{top.content}</p>
												</div>
											</div>
										</div>
									</div>
						</SwiperSlide>
						)})}
      			</Swiper>
			  </div>
			</div>
		  </div>
		</section>   
        <section id="featured" className="featured">
		  <div className="container">
			<div className="row">
				{props && props.data && props.data.length > 0 && props.data.slice(4, 8).map((topp, t)=>{
					return(
						<div className="col-lg-3 mt-4 mt-lg-0" key={t}>
							<div className="business-card">
								<div className="post-img">
									<div className="item">
										<NavLink className="" to={topp.slug}><img src={topp.image}/></NavLink>
									</div>                   
								</div>
								<div className="post-content">
									<h3 className="post-title">
										<NavLink to={topp.slug}>{topp.title}</NavLink>
									</h3>
									<NavLink to={topp.slug} className="more-btn">Read More</NavLink>
								</div>	
							</div>
						</div>                        
				)})}
			</div>

		  </div>
		</section>     
        </>
    )
}
export default Top;