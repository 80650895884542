import React from 'react';
import Home from './Home';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Category from './Category';
import Search from './Search';
import Detail from './Detail';
import Contact from './Contact';
import About from './About';
import Services from './Services';
import TechnicalSupport from './TechnicalSupport';
import Terms from './Terms';
import Career from './Career';
import Privacy from './Privacy';
import '../css/style.css';


const App = () =>{
  return(
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/technical-support" component={TechnicalSupport}/>
        <Route exact path="/about-us" component={About}/>
        <Route exact path="/terms-of-use" component={Terms}/>
        <Route exact path="/career" component={Career}/>
        <Route exact path="/privacy-policy" component={Privacy}/>
        <Route exact path="/search/:search" component={Search}/>
        <Route exact path="/contact-us" component={Contact}/>
        <Route exact path="/services" component={Services}/>        
        <Route exact path="/category/:categoryId" component={Category}/>
        <Route exact path="/detail/:newsId" component={Detail}/>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
